import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme):any => ({
  menuButton: {
    '& svg': {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600,
      
    },
    '&:hover, &:focus': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      outline: 'none'
    }
  },
  navigationDrawerWrapper: {
    '& .MuiPaper-root': {
      background: 'transparent'
    }
  },
  navigationDrawer: {
    width: 300,
    backgroundColor: '#fff',
    padding: theme.spacing(10, 2),
    flex: '1 1 auto',
    position: 'relative',
    '& nav': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: '100%',
      '& a': {
        fontSize: theme.typography.pxToRem(20),
        margin: theme.spacing(0),
        width: '100%'
      }
    }
  },
  menuCloseButton: {
    marginTop: theme.spacing(1) + 'px !important',
    position: 'absolute !important',
    top: 0,
    right: theme.spacing(2),
    '& svg': {
      fontSize: theme.typography.pxToRem(42),
      fontWeight: 600
    },
    '&:hover, &:focus': {
      cursor: 'pointer',
      color:theme.palette.primary.main,
      outline: 'none'
    }
  },
  iconWrapper:{
    color:theme.palette.secondary.main,
    margin:theme.spacing(5,0),
    justifyContent:'center',
    width:'20%',
    cursor:'pointer',
    '&:hover, &:focus': {
      color:theme.palette.primary.main,
      outline: 'none'
    }

  },
  introFormLink: {
    width: '100%',
    padding: theme.spacing(1, 10),
    fontSize: theme.typography.pxToRem(14),
    background: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    lineHeight: '1.5',
    borderRadius: theme.spacing(0.6),
    color: theme.palette.common.white,
    display: 'inline-block',
    textDecoration: 'none',
    textAlign: 'center',
    '&:hover,&:focus': {
      color: theme.palette.common.white,
      background: theme.palette.secondary.dark
    }
  },
}))
